import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog32/image1.png"
import image2 from "../../images/blog32/image2.jpg"
import image3 from "../../images/blog32/image3.jpg"







export default function WhyYourBackPainShouldNotStopYouFromBeingPhysicallyActive(){
    return(
        <BlogPostOld
            src={image}
            title={"Why Your Back Pain Should NOT Stop You From Being Physically Active"}
            summary={"When someone gets hurt or feels pain, what should they do? Should you rest when your back hurts? Does massage work for back pain? What exercises are good for back pain? Because I am a chiropractor and strength coach I can’t tell you how many times I have heard these questions. If I had a nickel for every time I hear these at every single family outings or when I meet someone at a party I would be a rich man in the 1920s."}
            date={"Mar. 15, 2023"}
            author={"Dr. Donald Mull, DC"}

        >
            <div>

                <p>When someone gets hurt or feels pain, what should they do? Should you rest when your back hurts? Does massage work for back pain? What exercises are good for back pain?</p>
                <p>Because I am a chiropractor and strength coach I can’t tell you how many times I have heard these questions. If I had a nickel for every time I hear these at every single family outings or when I meet someone at a party I would be a rich man in the 1920s.&nbsp;</p>
                <p>I can’t tell you how much I hate getting these questions because it tells me how far off we are as a society to helping manage people who experience lower back pain. There is a reason I spend 60-90 minutes with every patient I see for the first time. Because in most cases lower back pain (and all pain for that matter) is really complex and CANNOT be pinned down to a simple singular cause.&nbsp;</p>
                <img src={image2} alt={"blog"}/>
                <p>So when someone comes up to me and asks is *insert any intervention* good or bad for my lower back pain, a little part of me dies. It is not (entirely) because I am annoyed that someone is asking me to do my job at a social gathering. It is because the narrative that society is fed is that there is and/or should be a singular cause of lower back pain therefore there should be a singular or simple “cure” for lower back pain.&nbsp;</p>
                <p>This narrative makes people who happen to sell ergonomic equipment, medical devices, mattresses, pharmaceuticals, diagnostic imaging, etc a lot of money. But given the information that we have available to us in regards to lower back pain, this narrative is a flat out lie.&nbsp;</p>
                <p>I only have the bandwidth to cover if rest is best for lower back pain before I feel like I am preaching on my soapbox on Thanksgiving dinner after 1 or 4 glasses of wine. I know, sounds fun right? I will cover the others at a later date (your welcome).</p>
                <p><span style={{textDecoration: 'underline'}}><strong>Should you rest when your back hurts?</strong></span></p>
                <p>Avoiding physical activities that you enjoy when you are experiencing lower back pain is a very risky call. Fear of activity and lack of confidence that the painful experience will get better increases the risk that symptoms will persist for longer periods.&nbsp;</p>
                <p>Now imagine going to a physician after a week or so of back pain that won’t go away even though you have basically tried every stretch that exists on YouTube. They tell you that you need to stop running and weight lifting for your lower body for 6-8 weeks. So being a good patient, you oblige them.&nbsp;</p>
                <p>2 more weeks go by and work begins to pick up, the kids are driving you crazy and not only are you still in pain but you are more stressed than ever because running and the gym had always helped manage your stress in the past. However, you feel like you should listen to the doc because after all, they are the “expert” right?</p>
                <p>Now you are 6 weeks in with hardly any change in lower back symptoms so you are frustrated with both yourself and the physician that sold you this story of rest is best. So now not only are you fearful to do the activities you liked doing (because in a sense you were told to be) but you are now feeling less confident that things are going to get better. This sound familiar??</p>
                <p>Yet we wonder why the global burden attributed to lower back pain continues to rise despite all of our fancy products and diagnostic imaging. This shit isn’t working. Telling people to avoid activity isn’t working. Telling people to expect one reason for pain isn’t working.&nbsp;</p>
                <img src={image3} alt={"blog"}/>
                <p>Let’s try something else. Let’s put people over profit and actually learn about how they feel, what they want to do with their bodies then actually teach them how to accomplish this while encouraging sustainable healthy lifestyle habits. At least this is how I would want to be treated so I will be damned if those that come to Kinetic Impact aren’t treated the same.&nbsp;</p>
            </div>

        </BlogPostOld>

    )
}